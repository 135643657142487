import Vue from "vue";
import Vuetify from "vuetify";

import "vuetify/dist/vuetify.min.css";
import fa from "vuetify/lib/locale/fa";
import instagram from "../components/icons/instagram.vue";
import home from "../components/icons/home.vue";
import members from "../components/icons/members.vue";
import bag from "../components/icons/bag.vue";
import phone from "../components/icons/phone.vue";
import info from "../components/icons/info.vue";
import category from "../components/icons/category.vue";
import send from "../components/icons/send.vue";

Vue.use(Vuetify);

export default new Vuetify({
  theme: {
    themes: {
      light: {
        primary: "#6096a1",
        secondary: "#056a75",
        third: "#223f96"
        // error: "#FF5252",
        // info: "#2196F3",
        // success: "#4CAF50",
        // warning: "#FFC107"
      }
    }
  },
  rtl: true,
  lang: {
    locales: { fa },
    current: "fa"
  },
  icons: {
    iconfont: "mdi",
    values: {
      instagram: {
        component: instagram
      },
      home: {
        component: home
      },
      members: {
        component: members
      },
      bag: {
        component: bag
      },
      phone: {
        component: phone
      },
      info: {
        component: info
      },
      category: {
        component: category
      },
      send: {
        component: send
      }
    }
  }
});

<template>
  <v-app class="app-style">
    <router-view></router-view>
  </v-app>
</template>

<script>
export default {
  name: "app"
};
</script>

<style lang="scss">
// Import Main styles for this application
@import "assets/scss/style";

@font-face {
  font-family: iransans;
  src: url("assets/fonts/IRANSans(FaNum).ttf");
}

body {
  font-family: iransans !important;
}
.app-style {
  height: 100vh;
}
</style>

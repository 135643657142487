import Vue from "vue";
import Router from "vue-router";
Vue.use(Router);
// Containers
const DefaultContainer = () => import("@/containers/DefaultContainer");
const Home = () => import("@/views/Home");
const Laboratory = () => import("@/views/Laboratory");
const PhysicalTherapy = () => import("@/views/PhysicalTherapy");
const ContactUs = () => import("@/views/ContactUs");
const AboutUs = () => import("@/views/AboutUs");
const Page404 = () => import("@/views/Page404");

export default new Router({
  mode: "history", // https://router.vuejs.org/api/#mode
  linkActiveClass: "open active",
  scrollBehavior: () => ({ y: 0 }),
  routes: [
    {
      path: "/",
      redirect: "/home",
      name: "خانه",
      component: DefaultContainer,
      children: [
        {
          path: "/home",
          name: "صفحه اصلی",
          component: Home
        },
        {
          path: "/laboratory",
          name: "آزمایشگاه",
          component: Laboratory
        },
        {
          path: "/physicaltherapy",
          name: "فیزیوتراپی",
          component: PhysicalTherapy
        },
        {
          path: "/contactus",
          name: "تماس با ما",
          component: ContactUs
        },
        {
          path: "/aboutUs",
          name: "درباره ما",
          component: AboutUs
        }
      ]
    },
    { path: "*", component: Page404 }
  ]
});
